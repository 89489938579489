var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"sec no-bg"},[_c('b-radio-select-checkbox',{attrs:{"options":_vm.filters.ioOptions,"header":"Campaign"},on:{"selected-options":_vm.onSelectIO}}),(_vm.filters.ioOptions.length > 0 && !_vm.campaignsLoading && _vm.campaigns.length === 0)?_c('div',{staticClass:"form-inp"},[_c('k-button',{attrs:{"size":3,"label":"CREATE"},on:{"click":function($event){_vm.createDraft();
          _vm.campaignCounter++;}}})],1):_vm._e()],1),_c('div',{staticClass:"sec no-bg"},[_c('span',{staticClass:"ttl main-title"},[_vm._v("Campaign")]),_c('div',{staticClass:"sec camplist-box"},[_c('div',{staticClass:"table-width"},[(_vm.errorBrandCompetitor)?_c('div',{staticClass:"error"},[_vm._v(_vm._s(_vm.errorBrandCompetitor))]):_vm._e(),(!_vm.campaignsLoading && _vm.campaigns.length)?_c('div',[_c('table',[_vm._m(0),_vm._l((_vm.campaigns),function(row,ci){return _c('tr',{key:ci},[_c('td',[(!row.campaign)?_c('span',{staticClass:"intip4",attrs:{"data-tool-tip":row.reasonForFailure}},[_vm._v(" "+_vm._s(row.campaign_name)+" "),_c('font-awesome-icon',{staticClass:"campaign-error",attrs:{"icon":['fas', 'info-circle']}})],1):(row.campaign)?_c('span',{class:row.status.toUpperCase() === 'DRAFT' || row.status.toUpperCase() === 'PENDING'
                      ? ''
                      : 'intip4',attrs:{"data-tool-tip":_vm.theToolTip(_vm.campaignsStat)}},[_vm._v(" "+_vm._s(row.campaign_name)+" ")]):_vm._e()]),_c('td',[_vm._v(_vm._s(row.campaign_id))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(row.start_date)))]),_c('td',[_vm._v(_vm._s(_vm.formatDate(row.end_date)))]),_c('td',[_vm._v(_vm._s(row.status))]),_c('td',[(row.status && row.status.toUpperCase() !== 'DRAFT')?_c('span',{staticClass:"intip4",attrs:{"data-tool-tip":("" + (_vm.copyCodeText || 'Copy Pixel Code'))},on:{"click":function($event){return _vm.copyCodeToClipboard(row.campaign_id)}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'code']}})],1):_vm._e(),(row.status)?_c('span',{staticClass:"intip4",attrs:{"data-tool-tip":"Click here to view"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'eye']},on:{"click":function($event){_vm.campaignMode = 'VIEW';
                      _vm.selectedCampaign = row.campaign;
                      _vm.campaignCounter++;
                      _vm.clearMessage();}}})],1):_vm._e(),(row.status && row.status.toUpperCase() === 'DRAFT')?_c('span',{staticClass:"intip4",attrs:{"data-tool-tip":"Click here to edit"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'pencil']},on:{"click":function($event){_vm.campaignMode = 'EDIT';
                      _vm.selectedCampaign = row.campaign;
                      _vm.campaignCounter++;
                      _vm.clearMessage();}}})],1):_vm._e(),(row.status && row.status.toUpperCase() === 'DRAFT')?_c('span',{staticClass:"intip4",attrs:{"data-tool-tip":"Launch Campaign"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'rocket']},on:{"click":function($event){return _vm.launchCampaign(row.campaign)}}})],1):_vm._e(),(row.status && row.status.toUpperCase() === 'DRAFT')?_c('span',{staticClass:"intip4",attrs:{"data-tool-tip":"Delete Campaign"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'trash']},on:{"click":function($event){return _vm.onClickConfirm({ type: 'campaign ', value: row.campaign })}}})],1):_vm._e()])])})],2),(_vm.launching)?_c('b-loading-spinner',{staticClass:"txt-align-center ttl"}):_vm._e()],1):(!_vm.campaignsLoading && !_vm.campaigns.length)?_c('div',{staticClass:"txt-align-center ttl"},[_vm._v(" No data is available to show ")]):_c('b-loading-spinner',{staticClass:"txt-align-center ttl"})],1)])]),(_vm.selectedCampaign)?_c('add-edit-campaign',{key:_vm.campaignCounter,attrs:{"initial-campaign":_vm.selectedCampaign,"advertiser":_vm.selectedAdvertiser,"selected-i-o":_vm.selectedIO,"mode":_vm.campaignMode},on:{"update":_vm.handleCampaignUpdates}}):_vm._e(),(_vm.showConfirmBoxModal)?_c('ConfirmModal',{staticClass:"modal-mask",attrs:{"type":_vm.confirmationObj.type},on:{"cancel":function($event){_vm.showConfirmBoxModal = false},"remove":_vm.onClickConfirmSubmit}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',[_vm._v("Campaign Name")]),_c('th',[_vm._v("Campaign ID")]),_c('th',[_vm._v("Start Date")]),_c('th',[_vm._v("End Date")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Actions")])])}]

export { render, staticRenderFns }