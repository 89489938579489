<template>
  <div class="container">
    <div class="sec no-bg">
      <b-radio-select-checkbox
        :options="filters.ioOptions"
        header="Campaign"
        @selected-options="onSelectIO"
      />
      <div
        v-if="filters.ioOptions.length > 0 && !campaignsLoading && campaigns.length === 0"
        class="form-inp"
      >
        <k-button
          :size="3"
          label="CREATE"
          @click="
            createDraft();
            campaignCounter++;
          "
        />
      </div>
    </div>
    <div class="sec no-bg">
      <span class="ttl main-title">Campaign</span>

      <div class="sec camplist-box">
        <div class="table-width">
          <div v-if="errorBrandCompetitor" class="error">{{ errorBrandCompetitor }}</div>
          <div v-if="!campaignsLoading && campaigns.length">
            <table>
              <tr>
                <th>Campaign Name</th>
                <th>Campaign ID</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
              <tr v-for="(row, ci) in campaigns" :key="ci">
                <td>
                  <span v-if="!row.campaign" class="intip4" :data-tool-tip="row.reasonForFailure">
                    {{ row.campaign_name }}
                    <font-awesome-icon :icon="['fas', 'info-circle']" class="campaign-error" />
                  </span>

                  <span
                    v-else-if="row.campaign"
                    :class="
                      row.status.toUpperCase() === 'DRAFT' || row.status.toUpperCase() === 'PENDING'
                        ? ''
                        : 'intip4'
                    "
                    :data-tool-tip="theToolTip(campaignsStat)"
                  >
                    {{ row.campaign_name }}
                  </span>
                </td>
                <td>{{ row.campaign_id }}</td>
                <td>{{ formatDate(row.start_date) }}</td>
                <td>{{ formatDate(row.end_date) }}</td>
                <td>{{ row.status }}</td>
                <td>
                  <span
                    v-if="row.status && row.status.toUpperCase() !== 'DRAFT'"
                    class="intip4"
                    :data-tool-tip="`${copyCodeText || 'Copy Pixel Code'}`"
                    @click="copyCodeToClipboard(row.campaign_id)"
                  >
                    <font-awesome-icon :icon="['fas', 'code']" />
                  </span>
                  <span v-if="row.status" class="intip4" data-tool-tip="Click here to view">
                    <font-awesome-icon
                      :icon="['fas', 'eye']"
                      @click="
                        campaignMode = 'VIEW';
                        selectedCampaign = row.campaign;
                        campaignCounter++;
                        clearMessage();
                      "
                    />
                  </span>
                  <span
                    v-if="row.status && row.status.toUpperCase() === 'DRAFT'"
                    class="intip4"
                    data-tool-tip="Click here to edit"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'pencil']"
                      @click="
                        campaignMode = 'EDIT';
                        selectedCampaign = row.campaign;
                        campaignCounter++;
                        clearMessage();
                      "
                    />
                  </span>
                  <span
                    v-if="row.status && row.status.toUpperCase() === 'DRAFT'"
                    class="intip4"
                    data-tool-tip="Launch Campaign"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'rocket']"
                      @click="launchCampaign(row.campaign)"
                    />
                  </span>
                  <span
                    v-if="row.status && row.status.toUpperCase() === 'DRAFT'"
                    class="intip4"
                    data-tool-tip="Delete Campaign"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'trash']"
                      @click="onClickConfirm({ type: 'campaign ', value: row.campaign })"
                    />
                  </span>
                </td>
              </tr>
            </table>

            <b-loading-spinner v-if="launching" class="txt-align-center ttl" />
          </div>
          <div v-else-if="!campaignsLoading && !campaigns.length" class="txt-align-center ttl">
            No data is available to show
          </div>
          <b-loading-spinner v-else class="txt-align-center ttl" />
        </div>
      </div>
    </div>
    <add-edit-campaign
      v-if="selectedCampaign"
      :key="campaignCounter"
      :initial-campaign="selectedCampaign"
      :advertiser="selectedAdvertiser"
      :selected-i-o="selectedIO"
      :mode="campaignMode"
      @update="handleCampaignUpdates"
    />
    <ConfirmModal
      v-if="showConfirmBoxModal"
      class="modal-mask"
      :type="confirmationObj.type"
      @cancel="showConfirmBoxModal = false"
      @remove="onClickConfirmSubmit"
    />
  </div>
</template>

<script>
import { isBlank } from 'adready-api/helpers/common';
import { get } from 'vuex-pathify';
import moment from 'moment';
import advertiserReportsAPI from '~/api/advertiser-reports';
import { buildQueryString } from '~/helpers/global/url-helpers';
import * as util from '~/util/utility-functions';
import ConfirmModal from '~/pages/ConfirmModal.vue';
import { formatNumberAsFullNumber } from '~/util/numeral';

export default {
  name: 'BrandLiftCampaignSetup',
  components: {
    BRadioSelectCheckbox: () =>
      import(
        /* webpackChunkName: "b-radio-select-checkbox" */ '~/components/elements/b-radio-select-checkbox.vue'
      ),
    BLoadingSpinner: () =>
      import(
        /* webpackChunkName: "b-loading-spinner" */ '~/components/elements/b-loading-spinner.vue'
      ),
    AddEditCampaign: () =>
      import(/* webpackChunkName: "campaign" */ '~/components/brandlift/add-edit-campaign.vue'),
    ConfirmModal,
  },
  data() {
    return {
      filters: {
        ioOptions: [],
      },
      selectedAdvertiser: null,
      selectedIO: null,
      selectedCampaign: null,
      campaignsLoading: false,
      campaigns: [],
      campaignsStat: null,
      campaignCounter: 0,
      campaignMode: null,
      copyCodeText: '',
      ioOptions: [],
      errorBrandCompetitorMsg: null,
      showConfirmBoxModal: false,
      confirmationObj: null,
      launching: false,
    };
  },
  computed: {
    account: get('common/account'),
    advertiser: get('common/advertiser'),

    logoFile() {
      if (!this.advertiser) {
        return null;
      }

      return this.advertiser.logoFile ? this.advertiser.logoFile.id : null;
    },
    errorBrandCompetitor() {
      if (!isBlank(this.errorBrandCompetitorMsg)) {
        return this.errorBrandCompetitorMsg;
      }
      return null;
    },
  },
  watch: {
    /**
     *
     * This will fire:
     * - When advertiser is changed via dropdown select
     */

    advertiser(val) {
      this.selectedCampaign = null;
      this.selectedAdvertiser = val;
    },
    selectedAdvertiser: {
      immediate: true,
      async handler(adv) {
        if (!this.account || !adv) {
          return;
        }
        try {
          await this.fetchIos();
        } catch (err) {
          console.error('error switching advertiser on BrandLiftCampaignSetup ->', err);
          if (window.$sentry) {
            if (err._reported !== true) {
              window.$sentry.captureException(err);
              err._reported = true;
            }
          }
        }
      },
    },

    ioOptions: {
      immediate: true,
      async handler(n) {
        if (!n) {
          this.filters.ioOptions = [];
          this.selectedIO = null;
          await this.fetchBrandLiftCampaigns();
          return;
        }
        const nd = JSON.parse(JSON.stringify(n));
        nd.forEach((item, i) => {
          item.event = item.value;
          item.category = item.value;
          item.selected = i === 0;
          item.name = item.value;
        });
        this.filters.ioOptions = nd;
        [this.selectedIO] = nd;
        await this.fetchBrandLiftCampaigns();
      },
    },
  },

  async mounted() {
    if (!this.advertiser) {
      // this.$router.push({ name: 'Home' });
      return;
    }

    this.selectedAdvertiser = this.advertiser;

    await this.fetchIos();
    const ioOpts = JSON.parse(JSON.stringify(this.ioOptions));
    ioOpts.forEach((item, i) => {
      item.event = item.value;
      item.category = item.value;
      item.selected = i === 0;
      item.name = item.value;
    });

    this.filters.ioOptions = ioOpts;
    [this.selectedIO] = ioOpts;
    await this.fetchBrandLiftCampaigns();
  },
  methods: {
    theToolTip(stat) {
      if (isBlank(stat)) return '';
      const respondent = formatNumberAsFullNumber(stat.total_completes);
      const impression = formatNumberAsFullNumber(stat.total_impressions);
      return ` Total Respondents : ${respondent}
          Total Impressions : ${impression}`;
    },
    onClickConfirm(item) {
      this.errorBrandCompetitorMsg = null;
      this.confirmationObj = item;
      this.showConfirmBoxModal = true;
    },
    onClickConfirmSubmit() {
      this.deleteCampaign(this.confirmationObj.value);
      this.showConfirmBoxModal = false;
    },
    async fetchIos() {
      try {
        if (
          !this.account ||
          !this.selectedAdvertiser ||
          isBlank(this.account.name) ||
          isBlank(this.selectedAdvertiser.name) ||
          isBlank(this.selectedAdvertiser.xandrAdvertiserId)
        ) {
          return;
        }
        const data = await advertiserReportsAPI.ioNames(
          this.selectedAdvertiser ? this.selectedAdvertiser.id : 0,
          buildQueryString({
            advertiser: this.selectedAdvertiser ? this.selectedAdvertiser.name : '',
            client: this.account ? this.account.name : '',
            showAll: true,
            includeNonFlip: util.isFlamingoInstance(),
          })
        );

        if (data.length !== 0) {
          this.ioOptions = data;
          return;
        }

        this.ioOptions = [];
      } catch (err) {
        this.ioOptions = [];
        console.error('error fetching ios ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
        throw err;
      }
    },
    handleCampaignUpdates() {
      this.fetchBrandLiftCampaigns();
      this.selectedCampaign = null;
    },
    copyCodeToClipboard(campaignID = '') {
      if (!campaignID) {
        return;
      }

      navigator.clipboard.writeText(
        `https://tracker.samplicio.us/tracker/${campaignID}/pixel.gif?sid=Xandr&pid=\${CPG_ID}&crid=\${CREATIVE_ID}&device_id=\${DEVICE_APPLE_IDA}&cachebuster=\${CACHEBUSTER}`
      );
      this.copyCodeText = 'Pixel copied to clipboard!';
      setTimeout(() => {
        this.copyCodeText = '';
      }, 2000);
    },
    validateParams(payload, advertiserId) {
      if (isBlank(advertiserId)) {
        return false;
      }
      const keys = ['client', 'advertiser', 'io'];
      return !util.isAnyBlank(payload, keys);
    },
    async fetchBrandLiftCampaigns() {
      this.errorBrandCompetitorMsg = null;
      const payload = {
        client: this.account ? this.account.name : '',
        advertiser: this.advertiser ? this.advertiser.name : '',
        io: this.selectedIO ? this.selectedIO.name : '',
      };
      const advertiserId = this.advertiser ? this.advertiser.id : null;
      const dataValidated = this.validateParams(payload, advertiserId);
      if (!dataValidated) {
        this.campaigns = [];
        return;
      }

      try {
        this.campaignsLoading = true;
        const data = await advertiserReportsAPI.fetchBrandLiftCampaigns(
          this.advertiser ? this.advertiser.id : 0,
          buildQueryString(payload)
        );

        data.forEach((row) => {
          const d = row.campaign;
          if (!d) {
            row.campaign_name = 'Unable to fetch details for this campaign id';
            return;
          }
          row.campaign_name = d.campaign_name;
          row.status = d.status;
          row.start_date = d.start_date;
          row.end_date = d.end_date;
          d.draft_settings.questions.forEach((q) => {
            q.questionParams = q.questionParams || [];
            q.questionParams = q.questionParams.map((qp) => {
              return qp.param ? qp : { param: qp, value: '' };
            });
            q.answers = q.answers || [];
            q.answers = q.answers.map((m) => {
              return m.text ? m : { text: m };
            });
          });
          d.draft_settings.baseline_questions.forEach((q) => {
            q.questionParams = q.questionParams || [];
            q.questionParams = q.questionParams.map((qp) => {
              return qp.param ? qp : { param: qp, value: '' };
            });
            q.answers = q.answers || [];
            q.answers = q.answers.map((m) => {
              return m.text ? m : { text: m };
            });
          });
        });
        this.campaigns = data || [];
        this.campaignsStat = null;
        if (
          !isBlank(this.campaigns) &&
          this.campaigns[0]?.status.toUpperCase() !== 'DRAFT' &&
          this.campaigns[0]?.status.toUpperCase() !== 'PENDING'
        ) {
          this.fetchBrandLiftCampaignsStat();
        }
      } catch (err) {
        console.error('error fetching brandlift campaigns', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.campaignsLoading = false;
      }
    },
    async fetchBrandLiftCampaignsStat() {
      this.errorBrandCompetitorMsg = null;
      this.campaignsStat = null;
      const payload = {
        client: this.account ? this.account.name : '',
        advertiser: this.advertiser ? this.advertiser.name : '',
        io: this.selectedIO ? this.selectedIO.name : '',
      };

      try {
        this.campaignsLoading = true;
        const data = await advertiserReportsAPI.fetchBrandLiftCampaignsStat(
          this.advertiser ? this.advertiser.id : 0,
          buildQueryString(payload)
        );

        this.campaignsStat = data || [];
      } catch (err) {
        console.error('error fetching brandlift campaigns stats', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.campaignsLoading = false;
      }
    },
    async createDraft() {
      this.campaignMode = 'NEW';
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + 1);
      endDate.setUTCHours(23, 59, 59);
      const startDate = new Date();
      startDate.setUTCHours(0, 0, 0, 0);
      this.errorBrandCompetitorMsg = null;
      const payload = {
        campaign_id: '',
        campaign_name: this.selectedIO ? this.selectedIO.name : '',
        client_name:
          this.account && this.selectedAdvertiser
            ? `${this.account.name}-${this.selectedAdvertiser.name}`
            : '',
        country_language_id: null,
        draft_settings: {
          allow_ip: false,
          baseline_questions: [],
          expected_impressions_count: 1000000,
          questions: [],
          target_audiences: [],
        },
        end_date: endDate.toISOString(),
        start_date: startDate.toISOString(),
        status: 'draft',
        industry_id: null,
      };

      this.selectedCampaign = payload;
    },

    async onSelectIO(data) {
      this.selectedCampaign = null;
      this.campaigns = [];
      const selectedIO = data.find((obj) => obj.selected === true);
      this.selectedIO = selectedIO;
      this.errorBrandCompetitorMsg = null;
      try {
        await this.fetchBrandLiftCampaigns();
      } catch (err) {
        console.error('error switching campaign on BrandLiftCampaignSetup ->', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },

    formatDate(value) {
      if (value) {
        return moment(String(value)).format('MM/DD/YYYY');
      }
      return '';
    },
    clearMessage() {
      this.errorBrandCompetitorMsg = null;
    },
    getPlaceHolders(questions) {
      const arrayPlaceHolder = [];
      questions.forEach((q) => {
        if (q.question_text.includes('{{')) {
          const part = q.question_text.split('{{');
          part.forEach((i) => {
            if (i.includes('}}')) {
              const eachPart = `{{${i.substring(0, i.lastIndexOf('}') + 1)}`;
              arrayPlaceHolder.push(eachPart);
            }
          });
        }
      });
      return arrayPlaceHolder;
    },
    validatePlaceHolder(questions, campaignId) {
      const placeholders = this.getPlaceHolders(questions);
      this.errorBrandCompetitorMsg = null;
      const arr = [];
      questions.forEach((q) => {
        placeholders.forEach((p) => {
          if (
            q.question_text.includes(p) &&
            (q.custom_question_text.includes(p) ||
              (isBlank(q.custom_question_text) && !arr.includes(p)))
          ) {
            arr.push(`${p}`);
          }
        });
      });

      if (arr.length > 0) {
        this.errorBrandCompetitorMsg = 'Cannot launch Campaign Id : '
          .concat(campaignId)
          .concat(' Please enter the placeholders for : ')
          .concat(arr.join(','));
        return false;
      }
      return true;
    },
    validateCompititiveBrands(questions, campaignId) {
      const brands = [
        'Competitive Brand 1',
        'Competitive Brand 2',
        'Competitive Brand 3',
        'Competitive Brand 4',
        'Competitive Brand #1',
        'Competitive Brand #2',
        'Competitive Brand #3',
        'Competitive Brand #4',
      ];
      this.errorBrandCompetitorMsg = null;
      const arr = [];
      questions.forEach((q) => {
        q.answers = q.answers.map((m) => m.text);
        q.answers.forEach((v) => {
          if (brands.includes(v) && !arr.includes(v)) {
            arr.push(`${v}`);
          }
        });
      });

      if (arr.length > 0) {
        this.errorBrandCompetitorMsg = 'Cannot launch Campaign Id : '
          .concat(campaignId)
          .concat(' Please enter valid brand name for : ')
          .concat(arr.join(','));
        return false;
      }
      return true;
    },
    async deleteCampaign(selectedCampaign) {
      try {
        this.errorBrandCompetitorMsg = null;
        await advertiserReportsAPI.deleteBrandLiftCampaign([
          this.advertiser ? this.advertiser.id : 0,
          selectedCampaign.campaign_id,
        ]);
        this.handleCampaignUpdates();
      } catch (err) {
        this.errorMsg = 'Error deleting brandlift campaign';
        console.error('error deleting brandlift campaign', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      }
    },
    async launchCampaign(selectedCampaign) {
      this.launching = true;
      this.errorBrandCompetitorMsg = null;
      const campaignSelected = JSON.parse(JSON.stringify(selectedCampaign));
      const draftsettings = JSON.parse(JSON.stringify(campaignSelected.draft_settings));

      const validAnswer = this.validateCompititiveBrands(
        draftsettings.questions,
        selectedCampaign.campaign_id
      );
      if (!validAnswer) {
        this.launching = false;
        return;
      }

      const validPlaceholder = this.validatePlaceHolder(
        draftsettings.questions,
        selectedCampaign.campaign_id
      );
      if (!validPlaceholder) {
        this.launching = false;
        return;
      }

      try {
        await advertiserReportsAPI.launchBrandLiftCampaign([
          this.advertiser ? this.advertiser.id : 0,
          selectedCampaign.campaign_id,
        ]);
        this.handleCampaignUpdates();
      } catch (err) {
        this.errorMsg = 'Error launching brandlift campaign';
        console.error('error launching brandlift campaign', err);
        if (window.$sentry) {
          if (err._reported !== true) {
            window.$sentry.captureException(err);
            err._reported = true;
          }
        }
      } finally {
        this.launching = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.question,
.answers {
  display: flex;
  width: 100%;
  .ttl {
    width: 80%;
  }
  & > *:first-child {
    // flex: 0 1 20%;
    width: 18%;
    color: #97a7bb;
  }

  &:not(:first-child) {
    margin-top: 1rem;
  }
}
.lbl-txt {
  color: #97a7bb;
}
.qna {
  height: 50rem;
  overflow: scroll;
}

.ans-select {
  width: 780px;
  padding: 1rem;
  margin-top: 15px;
  color: #cad1d6;
  background-color: #323239;
  border-radius: 4px;
  &:first-child {
    margin-top: 5px;
  }
  &:hover {
    border: 1px solid var(--adminprimarycolor);
  }
}

.nav-tabs {
  position: absolute;
  top: 0;
  left: 16px;
  li {
    min-width: 70px;
    text-align: center;
    span {
      font-size: 13px;
      font-weight: 400;
    }
  }
}

::v-deep .nav-tabs li a {
  padding: 6px 30px !important;
}
table {
  width: 100%;
  padding: 1rem;
  font-size: 0.875rem;
  color: #cad1d6;
  tr {
    line-height: 50px;
    border-bottom: 1px solid #404040;
    &:first-child {
      border-bottom: 1px solid #858585;
    }
    td {
      & > svg {
        margin-left: 1rem;
      }
    }
  }
  tr th,
  td {
    padding: 0.5rem 1rem;
    &:last-child {
      text-align: end;
    }
  }
}

.sec {
  position: relative;
  display: block;
  padding: 1rem;
  margin-top: 1rem;
  background-color: #212429;
  border-radius: 4px;
  svg {
    cursor: pointer;
    title {
      margin-left: 4px;
      left: 4px;
    }
  }
}

.ttl {
  display: inline-block;
  font-size: 0.875rem;
  color: #cad1d6;
  letter-spacing: 0.07em;
}

.txt-align-center {
  width: 100%;
  font-size: 13px;
  color: #bac0c5;
  text-align: center;
}
.container > * {
  width: 1100px;
  margin: 0 auto;
}

.campaign-form {
  display: block;

  .form-inp {
    &:first-child {
      display: block;
    }
  }
}

.form-inp {
  display: inline-block;
  padding: 1rem;

  label {
    width: 110px;
    margin-right: 2rem;
  }
  .camp-name {
    width: 850px;
  }
}
::v-deep .form-inp label {
  cursor: default !important;
}
.form-date-inp {
  display: flex;
  padding: 1rem;
  align-items: center;
  label {
    width: 80px;
    margin-right: 4rem;
    cursor: default;
  }
  .last-lbl {
    margin-left: 2.9rem;
    margin-right: 3rem;
  }
  .date-box {
    width: 338px;
    color: #cad1d6;
  }
}
::v-deep .form-input.form-date .form-input-field {
  border-radius: 4px;
}
input,
select {
  padding: 15px;
  color: #cad1d6;
  background-color: #323239;
  border: none;
  border-radius: 4px;
  &::placeholder {
    color: #cad1d6;
  }
}

select {
  padding: 12px;
}
.lookup {
  padding: 25px;
  label {
    width: 130px;
  }
  select {
    width: 315px;
  }
}
.no-bg {
  background: transparent !important;
}
::v-deep .k-btn {
  display: inline-block;
  min-width: 75px;
  margin-right: 20px;
  font-weight: 600;
  color: #303335 !important;
  text-transform: uppercase;
  letter-spacing: 0.07em;
  background-color: var(--adminprimarycolor);
  border-radius: 3px;
  appearance: none;
}
::v-deep .k-btn.btn-size-3 span.label {
  padding: 9px 22px;
  line-height: 20px;
}
::v-deep .k-btn:hover {
  color: #cad1d6 !important;
  background-color: #424242 !important;
}
::v-deep .wide-box {
  min-width: 350px !important;
}
.actions-btn {
  margin: 40px 5px;
}
.main-title {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  text-transform: uppercase;
}
.camplist-box {
  min-height: 150px;
  align-items: center;
  display: flex;
}
.qa-box {
  margin-top: 25px;
}
.param-box {
  border: 1px solid hsla(0, 0%, 66.7%, 0.1);
}
// Tooltip CSS
::v-deep .intip4,
.mg-lr-4 {
  margin: 0 4px;
}
::v-deep .intip4[data-tool-tip]::after {
  position: absolute;
  left: 0;
  z-index: 99;
  display: block;
  line-height: 1.2em;
  color: #5b5b5b;
  text-align: center;
  pointer-events: none;
  content: attr(data-tool-tip);
  background-color: #fdea85;
  border-radius: 3px;
  transition: ease 0.3s;
  transform: scale(0);
  min-width: 170px;
  width: 100%;
  transform-origin: bottom center;
  bottom: 100%;
  padding: 10px 13px;
  margin-left: -75px !important;
  font-size: 12px;
  font-weight: 400;
  color: #222;
  text-align: center;
  pointer-events: none;
  background-color: rgba(245, 245, 250, 0.9);
}
.intip4[data-tool-tip]:hover::after {
  bottom: 115%;
  transform: scale(1);
}
// Tooltip CSS

.campaign-error {
  margin-left: 5px;
  color: #ff3d57;
}
.error {
  padding: 10px;
  font-size: 13px;
  color: rgba(252, 66, 66);
  letter-spacing: 0.9px;
}
.table-width {
  width: 100%;
}
.modal-mask {
  left: unset !important;
}
::v-deep .modal-mask {
  .modal-container {
    background-color: #212429;
    min-height: 130px;
  }
  .modal-wrapper {
    margin: 0 auto;
  }
}
</style>
